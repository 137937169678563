<template>
  <div class="container">
    <div class="row">
      <div class="col-12 py-4">

        <div v-if="userRole != 'Analyst'">
          <div class="card">
            <DataTable 
              :value="companies" 
              :loading="loading" 
              responsiveLayout="scroll" 
              showGridlines 
              :paginator="true"
              
              :rows="rowsPagValue" 
              :rowsPerPageOptions="[25, 50, 100]" 
              :rowHover="true" removableSort 
              :first="firstPagValue"

              
              @row-click="open" 
              
              dataKey="id"
              paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
              currentPageReportTemplate="Showing {first} to {last} of {totalRecords}" 
              v-model:filters="filters"
              filterDisplay="menu"
              :sort-field="sortFieldValue" 
              :sort-order="sortOrderValue" 
              @sort="onSort($event)"
              @page="onSort($event)"
            >
              <template #header>
                <div style="display:flex; justify-content: space-between; ">
                  <h5 class="mb-2 md:m-0 p-as-md-center">Companies</h5>
                  <!-- Usability Update 04-03-2024 Hide Selected Companies -->
                  <!-- <div class="mb-2 md:m-0 p-as-md-center" ><label >show <strong>Selected</strong> Companies</label> <InputSwitch v-model="priority" style="margin-left: 0.7rem;"/> </div> -->
                </div>
                <div class="table-header row gy-3 d-flex flex-row justify-content-between">
                  <div class="col-sm-12 col-md-4 d-flex flex-row justify-content-md-start">

                    <!-- CreateCompany -->
                    <Button
                      type="button"
                      icon="pi pi-plus"
                      label="Add"
                      class="p-button-outlined"
                      @click="goToCreateCompany()"
                    />
                  </div>
                  <div class="col-sm-12 col-md-8">
                    <div class="d-flex flex-row justify-content-md-end">
                      <Button 
                        type="button" 
                        icon="pi pi-filter-slash" 
                        label="Clear" 
                        class="p-button-outlined me-4"
                        @click="clearFilter()" 
                      />
                      <span class="p-input-icon-left" style="position: relative;">
                        <i class="pi pi-search" style="position: absolute; left: 5%; top:50%" />
                        <InputText 
                          v-model="filters['global'].value" 
                          placeholder="Keyword Search" 
                          class="keywordSearch" 
                        />
                      </span>
                    </div>
                  </div>
                </div>
              </template>

              <!-- Column for columns (Name, type) -->
              <Column 
                v-for="col of columns" 
                :field="col.field" 
                :header="col.header" 
                :key="col.field" 
                :sortable="true"
              >
              </Column>

              <Column
                :header="'Topics per Company'"
                :key="'Topics per Company'"
                bodyStyle="text-align: right"
                field="totalTopicCompany"
                :sortable="true"
              >
                <template #body="slotProps">
                  {{slotProps.data.totalTopicCompany}}
                </template>
              </Column>

              <!-- documents length -->
              <Column 
                :header="'Document'" 
                :key="'Document count'" 
                field="totalDocuments"
                :sortable="true"
                bodyStyle="text-align: right"
              >
                <template #body="slotProps">
                  {{slotProps.data.totalDocuments}}
                </template>
              </Column>

              <!-- Programs Length -->
              <Column
                :header="'Programme '"
                :key="'Program Count'"
                :sortable="true"
                field="totalPrograms"
                bodyStyle="text-align: right"
              >
                <template #body="slotProps">
                  {{ slotProps.data.totalPrograms  }}
                </template>
              </Column>

              <!-- Date Created -->
              <Column 
                :field="'dateCreated'" 
                :header="'Created'" 
                :key="'dateCreated'" 
                :sortable="true"
                bodyStyle="text-align: center"
              >
                <template #body="slotProps">
                  {{ formatDate(slotProps.data.dateCreated) }}
                </template>
              </Column>

              <!-- Date Updated -->
              <Column 
                :field="'dateUpdated'" 
                :header="'Updated'" 
                :key="'dateUpdated'" 
                :sortable="true" 
                bodyStyle="text-align: center"
              >
                <template #body="slotProps">
                  {{ formatDate(slotProps.data.dateUpdated) }}
                </template>
              </Column>

              <!-- Remove Button -->
              <Column 
                :exportable="false" 
                style="width: 10rem"
              >
                <template #body="slotProps">
                  <div style="display: grid; place-items: center;">
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger"
                      @click="openDeleteDialog(slotProps.data)" />
                  </div>
                </template>
              </Column>

              <template #paginatorstart>
                <Button type="button" icon="pi pi-refresh" class="p-button-text" @click="getCompanies()" />
              </template>
            </DataTable>
          </div>
        </div>
        <div v-else>
          <h1 style="color: red;"> You are not authorized</h1>
        </div>

        <DeleteCompany :selectedElement="selectedDeleteRow" @closeDialog="closeDialog($event)" />

        <EditCompany :selectedElement="selectedEditRow" @closeDialog="closeDialog($event)" />

      </div>
    </div>
  </div>


</template>

<script>
import formatToGlobalDate from '../../../../shared/helpers/date.helper';
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
// import InputSwitch from 'primevue/inputswitch';
import { FilterMatchMode, FilterOperator } from 'primevue/api';
import { useToast } from 'vue-toastification';
import DeleteCompany from './DeleteCompany.vue';
import EditCompany from './EditCompany.vue';
const toast = useToast();

export default {
  name: 'CompaniesList',
  components: {
    DataTable,
    Column,
    Button,
    InputText,
    // InputSwitch,
    DeleteCompany,
    EditCompany,
  },
  data() {
    return {
      priority: false,
      columns: null,
      loading: true,
      filters: null,
      selectedEditRow: null,
      selectedDeleteRow: null,
      
      //sortValues
      sortFieldValue: null,
      sortOrderValue: null,
      //paginationCountValues
      firstPagValue :null, 
      rowsPagValue: 25,
    };
  },
  created() {
    this.columns = [
      { field: 'name', header: 'Name' },
      { field: 'companyType.name', header: 'Type' },
    ];
    this.initFilters();
  },
  async mounted() {
    await this.getCompanies();
    this.setCompaniesSort();
  },

  computed: {
    userRole() {
      return this.$store.getters.obtainCurrentUserRole;
    },
    companies() {
      let companies = this.$store.getters.obtainCompanies
      companies.forEach(company => {
        company.totalTopicCompany = company.companyTopics.length
      });
      
      return companies;
    },
    companySort(){
      return this.$store.getters.obtainCompanySort;
    }
  },
 
  methods: {
    async getCompanies() {
      try {
        this.loading = true;
        if(this.userRole != 'Analyst'){
          await this.$store.dispatch('obtainFilteredCompanies',{totalDocuments:true, hasPriority: false, totalPrograms: true });
          // toast.success('Companies obtained successfully.');
        }
        this.loading = false;
      } catch (error) {
        toast.error(error.message);
        this.loading = false;
      }
    },

    setCompaniesSort(){
      try {
        //priority
        this.priority = this.companySort.priority
        
        //sort
        this.sortFieldValue = this.companySort.sortField; 
        this.sortOrderValue = this.companySort.sortOrder;

        //pag
        if(this.companySort.firstPagValue === null){
          this.firstPagValue = 0;
        }
        else{
          this.firstPagValue = this.companySort.firstPagValue;
        }
        if(this.companySort.rowsPagValue === null){
          this.rowsPagValue = 25;
        }
        else{
          this.rowsPagValue = this.companySort.rowsPagValue
        }
      } catch (error) {
        console.log(error);
      }

    },

    formatDate(date) {
      return formatToGlobalDate(date);
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: {
          operator: FilterOperator.AND,
          constraints: [
            { value: null, matchMode: FilterMatchMode.STARTS_WITH },
          ],
        },
      };
    },
    clearFilter() {
      this.initFilters();
    },
    goToCreateCompany(){
      this.$router.push(`./companies/company/new`);
    },
    open(event) {
      this.$router.push(`./companies/company/${event.data.companyId}`);
    },
    openDeleteDialog(element) {
      this.selectedDeleteRow = element;
    },
    closeDialog() {
      this.selectedEditRow = null;
      this.selectedDeleteRow = null;
    },

    //sortFunction 
    onSort(e){
      const companySort= {
        sortField: e.sortField,
        sortOrder: e.sortOrder,
        firstPagValue: e.first,
        rowsPagValue: e.rows,
        priority: this.priority
      }
      this.$store.dispatch('updateCompanySort', companySort);
    }
  },
  watch: {
        async priority(){
            await this.getCompanies()
        }
    }
};
</script>

<style scoped>
.keywordSearch{
  padding: 0.2rem 0 0.2rem 2.5rem  !important;
}
</style>